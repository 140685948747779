import React from 'react';
import Portfolio from './pages/index';

function App() {
  return (
    <Portfolio />
  );
}

export default App;
