import { socialTwitter } from 'react-icons-kit/ionicons/socialTwitter';
import { socialFacebook } from 'react-icons-kit/ionicons/socialFacebook';
import { socialDribbbleOutline } from 'react-icons-kit/ionicons/socialDribbbleOutline';
import { socialGithub } from 'react-icons-kit/ionicons/socialGithub';
import { socialGoogleplusOutline } from 'react-icons-kit/ionicons/socialGoogleplusOutline';

import Awardee1 from '../../assets/image/portfolio/awardee-1.png';
import Awardee2 from '../../assets/image/portfolio/awardee-2.png';
import Awardee3 from '../../assets/image/portfolio/awardee-3.png';
import Awardee4 from '../../assets/image/portfolio/awardee-4.png';
import AwardImage1 from '../../assets/image/portfolio/award-1.png';
import AwardImage2 from '../../assets/image/portfolio/award-2.png';
import AwardImage3 from '../../assets/image/portfolio/award-3.png';
import AwardImage4 from '../../assets/image/portfolio/award-4.png';

import CofounderImg from '../../assets/image/portfolio/remote-jobs.png';
import RemoteJobsImg from '../../assets/image/portfolio/remote-jobs.png';
import GameNewsImg from '../../assets/image/portfolio/game-news.png';
import PsikologCepteImg from '../../assets/image/portfolio/psikolog-cepte.png';
import FilmTavsiyeleriImg from '../../assets/image/portfolio/film-tavsiyeleri.png';
import DesignImg from '../../assets/image/portfolio/ui-ux.jpg';
import SoftwareConsultancyImg from '../../assets/image/portfolio/software-consultancy.jpg';

import Step1 from '../../assets/image/portfolio/step-1.png';
import Step2 from '../../assets/image/portfolio/step-2.png';
import Step3 from '../../assets/image/portfolio/step-3.png';

import SkillIcon1 from '../../assets/image/portfolio/skill-1.svg';
import SkillIcon2 from '../../assets/image/portfolio/skill-2.svg';
import SkillIcon3 from '../../assets/image/portfolio/skill-3.svg';
import SkillIcon4 from '../../assets/image/portfolio/skill-4.svg';

import Client1 from '../../assets/image/portfolio/client-1.png';
import Client2 from '../../assets/image/portfolio/client-2.png';
import Client3 from '../../assets/image/portfolio/client-3.png';
import Client4 from '../../assets/image/portfolio/client-4.png';
import Client5 from '../../assets/image/portfolio/client-5.png';
import Client6 from '../../assets/image/portfolio/client-6.png';

import Reviewer1 from '../../assets/image/portfolio/client-avatar-1.jpg';
import Reviewer2 from '../../assets/image/portfolio/client-avatar-2.jpg';
import Reviewer3 from '../../assets/image/portfolio/client-avatar-3.jpg';

export const SOCIAL_PROFILES = [
  {
    icon: socialTwitter,
    url: '#',
  },
  {
    icon: socialFacebook,
    url: '#',
  },
  {
    icon: socialDribbbleOutline,
    url: '#',
  },
  {
    icon: socialGithub,
    url: '#',
  },
  {
    icon: socialGoogleplusOutline,
    url: '#',
  },
];

export const MENU_ITEMS = [
  {
    label: 'ME',
    path: '#banner_section',
    offset: '0',
  },
  {
    label: 'PROJECT',
    path: '#portfolio_section',
    offset: '0',
  },
  {
    label: 'AWARDS',
    path: '#awards_section',
    offset: '0',
  },
  {
    label: 'WHY ME?',
    path: '#process_section',
    offset: '0',
  },
];

export const AWARDS = [
  {
    awardLogo: AwardImage1,
    awardName: 'Free Software Advice',
    awardDetails: 'Top Rated App Development Companies USA',
    awardeeLogo: Awardee1,
    awardeeName: 'Awardee',
    date: 'The Jury 2018',
  },
  {
    awardLogo: AwardImage2,
    awardName: 'Free Software Advice',
    awardDetails: 'Top Rated App Development Companies USA',
    awardeeLogo: Awardee2,
    awardeeName: 'Awardee',
    date: 'The Jury 2018',
  },
  {
    awardLogo: AwardImage3,
    awardName: 'Free Software Advice',
    awardDetails: 'Top Rated App Development Companies USA',
    awardeeLogo: Awardee3,
    awardeeName: 'Awardee',
    date: 'The Jury 2018',
  },
  {
    awardLogo: AwardImage4,
    awardName: 'Free Software Advice',
    awardDetails: 'Top Rated App Development Companies USA',
    awardeeLogo: Awardee4,
    awardeeName: 'Awardee',
    date: 'The Jury 2018',
  },
];

export const PORTFOLIO_SHOWCASE = [
  {
    title: 'WEB DEVELOPMENT',
    portfolioItem: [
      /*
      {
        title: 'Find Co-founder',
        description:
          '"Find Co-founder"​ is a platform to find co-founders for entrepreneurs & startups.',
        image: CofounderImg,
        link: 'https://co-founder.global',
        featuredIn: 'AWWWARDS',
        featuredLink: '#',
        view: '4.5K',
        love: '1.5K',
        feedback: '1.2K',
        buildWith: [
          {
            content: 'Angular'
          },
          {
            content: 'NodeJS'
          },
          {
            content: 'ExpressJS'
          }
        ],
      },
      */
      {
        title: 'Remote Jobs',
        description:
        '"Remote Jobs"​ is a platform to find and list remote-only jobs world wide. Its\' user friendly interface allows users to browse job opportunities from the most remote-friendly companies in the world. Companies can create remote job posts and find the right candidates easily. "Remote Jobs"​ platform matches the most suitable candidates with the most suitable remote job adverts.',
        image: RemoteJobsImg,
        link: 'https://remote-jobs.global',
        featuredIn: 'AppStore',
        featuredLink: '#',
        view: '8.5K',
        love: '5.5K',
        feedback: '3.2K',
        buildWith: [
          {
            content: 'Angular'
          },
          {
            content: 'NodeJS'
          },
          {
            content: 'ExpressJS'
          }
        ],
      },
    ],
  },
  {
    title: 'MOBILE APP DEVELOPMENT',
    portfolioItem: [
      {
        title: 'Game News',
        description:
          "Game News is a mobile application where users can discover the latest video games...",
        image: GameNewsImg,
        link: 'https://play.google.com/store/apps/details?id=com.demirtas.gamenews',
        featuredIn: 'AWWWARDS',
        featuredLink: '#',
        view: '4.5K',
        love: '1.5K',
        feedback: '1.2K',
        buildWith: [
          {
            content: 'React Native',
          },
          {
            content: 'Redux',
          },
          {
            content: 'NodeJS',
          },
          {
            content: 'MongoDB',
          },
        ],
      },
      {
        title: 'Psikolog Cepte',
        description:
          "Psikolog Cepte is a mobile application which helps its’ users access to great articles & books about psychology & psychiatry and discover the psychologists & psychiatrists in their cities.",
        image: PsikologCepteImg,
        link: 'https://play.google.com/store/apps/details?id=com.psikologcepte',
        featuredIn: 'AppStore',
        featuredLink: '#',
        view: '8.5K',
        love: '5.5K',
        feedback: '3.2K',
        buildWith: [
          {
            content: 'Ionic Framework',
          },
          {
            content: 'Angular',
          }
        ],
      },
      {
        title: 'Film Tavsiyeleri',
        description:
          "Film Tavsiyeleri is a mobile application where users can see movie recommendations based on the movie categories they choose.",
        image: FilmTavsiyeleriImg,
        link: 'https://play.google.com/store/apps/details?id=com.filmtavsiyeleri',
        featuredIn: 'AppStore',
        featuredLink: '#',
        view: '8.5K',
        love: '5.5K',
        feedback: '3.2K',
        buildWith: [
          {
            content: 'React Native',
          },
          {
            content: 'NodeJS',
          },
          {
            content: 'MongoDB',
          }
        ],
      },
    ],
  },
  {
    title: 'UI & UX DESIGN',
    portfolioItem: [
      {
        title: 'Design Focused Development',
        description:
          "We at Quaxo Labs, believe that good design has the power to move markets and the people you do business with. Good design is clear, simple, and user experience driven.",
        image: DesignImg,
        link: '#'
      }
    ],
  },
  {
    title: 'SOFTWARE CONSULTANCY & TRAINING',
    portfolioItem: [
      {
        title: 'Top notch software development support',
        description:
          "If you need help identifying the technology barriers that stand between you and a more profitable business, our software consultants can help.",
        image: SoftwareConsultancyImg,
        link: '#'
      }
    ],
  },
];

export const PROCESS_STEPS = [
  {
    image: Step1,
    title: '1. Research',
    description:
      'We work with you to understand user’s stories and validate your idea with real users using lean design sprints.',
  },
  {
    image: Step2,
    title: '2. Design',
    description:
      'Expanding on the insights gained, you’ll stay in touch with our design team to create an elegant design',
  },
  {
    image: Step3,
    title: '3. Build',
    description:
      'With our scrum-based agile methodology, you’ll receive iterative builds every two weeks, which gives you better insight for the progress.',
  },
];

export const SERVICE_LIST = [
  {
    title: 'Web Development',
    listItems: [
      {
        content: 'React & Redux',
      },
      {
        content: 'Angular',
      },
      {
        content: 'Vue',
      },
      {
        content: 'NodeJS',
      }
    ],
  },
  {
    title: 'Mobile App Development',
    listItems: [
      {
        content: 'Android & iOS',
      },
      {
        content: 'React Native',
      },
      {
        content: 'Ionic & Apache Cordova',
      },
      {
        content: 'GraphQL',
      },
    ],
  },
  {
    title: 'UI/UX Design',
    listItems: [
      {
        content: 'Web App Design',
      },
      {
        content: 'Mobile App Design',
      },
      {
        content: 'Visual Design',
      }
    ],
  },
];

export const SKILLS = [
  {
    title: 'Graphic Design',
    description:
      'Aristotle maintained the sharp distinction between science and the practical',
    icon: SkillIcon1,
    successRate: '90',
  },
  {
    title: 'UI/UX Design',
    description:
      'Aristotle maintained the sharp distinction between science and the practical',
    icon: SkillIcon2,
    successRate: '85',
  },
  {
    title: 'Web Application',
    description:
      'Aristotle maintained the sharp distinction between science and the practical',
    icon: SkillIcon3,
    successRate: '80',
  },
  {
    title: 'Mobile Application',
    description:
      'Aristotle maintained the sharp distinction between science and the practical',
    icon: SkillIcon4,
    successRate: '70',
  },
];

export const CLIENTS = [
  {
    image: Client1,
    title: 'Microsoft',
  },
  {
    image: Client2,
    title: 'Airbnb',
  },
  {
    image: Client3,
    title: 'Adidas',
  },
  {
    image: Client4,
    title: 'IBM',
  },
  {
    image: Client5,
    title: 'Amazon',
  },
  {
    image: Client6,
    title: 'Google',
  },
];

export const TESTIMONIAL = [
  {
    image: Reviewer1,
    review:
      'Another quality React-based product from RedQ Team. Manage to turn highly complex tech into simple components.',
    name: 'Thomas Cruz',
    designation: 'Founder & CEO',
    twitterProfile: 'https://twitter.com/redqinc',
    organization: '@Tonquin',
    organizationURL: 'https://redq.io/',
  },
  {
    image: Reviewer2,
    review:
      'Another quality React-based product from RedQ Team. Manage to turn highly complex tech into simple components.',
    name: 'Marhta Robson',
    designation: 'Co-Founder & CTO',
    twitterProfile: 'https://twitter.com/redqinc',
    organization: '@Tonquin',
    organizationURL: 'https://redq.io/',
  },
  {
    image: Reviewer3,
    review:
      'Another quality React-based product from RedQ Team. Manage to turn highly complex tech into simple components.',
    name: 'Dexter Patterson',
    designation: 'Co-Founder & COO',
    twitterProfile: 'https://twitter.com/redqinc',
    organization: '@Tonquin',
    organizationURL: 'https://redq.io/',
  },
];

export const FOOTER_MENU = [
  {
    label: 'Contact',
    path: '#',
  },
  {
    label: 'Privacy',
    path: '#',
  },
  {
    label: 'Cookie Policy',
    path: '#',
  },
];
