import axios from 'axios';
import { mainAPI } from '../../constants';

export const subscribeService = mail => {

    return new Promise( (resolve, reject) => {
        axios.post(`${mainAPI}addSubscription`, { mail })
        .then(res => resolve(res.data))  
        .catch(error => console.log(error));
    });

}