import React from 'react';

import AuthorOne from '../../assets/image/crypto/author-1.png';
import AuthorTwo from '../../assets/image/crypto/author-2.png';
import AuthorThree from '../../assets/image/crypto/author-3.png';
import AuthorFour from '../../assets/image/crypto/author-1.png';
import FEATURE1 from '../../assets/image/crypto/tf1.svg';
import FEATURE2 from '../../assets/image/crypto/tf2.svg';
import FEATURE3 from '../../assets/image/crypto/tf3.svg';
import FEATURE4 from '../../assets/image/crypto/tf4.svg';
import PROOF1 from '../../assets/image/crypto/proof1.svg';
import PROOF2 from '../../assets/image/crypto/proof2.svg';
import PROOF3 from '../../assets/image/crypto/proof3.svg';
import PROOF4 from '../../assets/image/crypto/proof4.svg';
import PROOF5 from '../../assets/image/crypto/proof5.svg';
import PROOF6 from '../../assets/image/crypto/proof6.svg';
import JACKPOTIMG from '../../assets/image/crypto/jackpot.svg';
import BETA1 from '../../assets/image/crypto/beta-1.svg';
import BETA2 from '../../assets/image/crypto/beta-2.svg';
import BETA3 from '../../assets/image/crypto/beta-3.svg';

export const MENU_ITEMS = [
  {
    label: 'Home',
    path: '#banner_section',
    offset: '0',
  },
  {
    label: 'Work',
    path: '#portfolio_section',
    offset: '100',
  },
  {
    label: 'Services',
    path: '#services',
    offset: '70',
  },
  {
    label: 'About Us',
    path: '#about-us',
    offset: '100',
  },

  {
    label: 'Career',
    staticLink: true,
    target:"_blank",
    path: 'https://angel.co/company/quaxo',
    offset: '-10',
  },
  {
    label: 'Contact Us',
    staticLink: true,
    path: 'mailto:hello@quaxo.tech'
  },
  
];

export const TESTIMONIALS = [
  {
    review:
      'Best working experience with this amazing team. We want to work together again in the future.',
    name: 'Denny Hilguston',
    designation: 'Project Manager',
    avatar: `${AuthorOne}`,
  },
  {
    review:
      'We have worked with a lot of software development companies. Quaxo is the only one we are happy to work with.',
    name: 'Justin Albuz',
    designation: 'CTO',
    avatar: `${AuthorThree}`,
  },
  {
    review:
      'Impressed with master class support of the team and the project development process.',
    name: 'Milly Cristiana',
    designation: 'Designer',
    avatar: `${AuthorTwo}`,
  },
];
export const TRANSACTIONS_FEATURE = [
  {
    image: FEATURE1,
    title: 'Web Development',
    des: 'High standard web development practices with result-oriented developers',
  },
  {
    image: FEATURE2,
    title: 'Mobile Development',
    des: 'Top notch mobile apps with beautiful design. We deliver full-cycle mobile development services.',
  },
  {
    image: FEATURE3,
    title: 'Software Consultancy',
    des: 'We are always here to help you for your software development process.',
  },
  {
    image: FEATURE4,
    title: 'Software Training',
    des: 'Get ahead of the curve with our software training services.',
  },
];
export const PROOFS_FEATURE = [
  {
    image: <i style={{fontSize: 30, color: '#6A52FD'}} className="fas fa-search" />,
    title: 'Easy project tracking',
    des: 'See the progress of your project.',
  },
  {
    image: <i style={{fontSize: 30, color: '#6A52FD'}} className="fas fa-mobile-alt" />,
    title: 'Simple UI',
    des: 'Easy to use interface.',
  },
  {
    image: <i style={{fontSize: 30, color: '#6A52FD'}} className="far fa-comment-dots" />,
    title: 'Comment Feature',
    des: 'Add comments for the project.',
  },
  {
    image: <i style={{fontSize: 30, color: '#6A52FD'}} className="fas fa-phone" />,
    title: 'Great support',
    des: 'Ticket system',
  },
];
export const SCALABLE_FEATURE = [
  {
    image: JACKPOTIMG,
    title: 'Daily Jackpot',
    des: '35000 CLV',
  },
  {
    image: JACKPOTIMG,
    title: 'Weekly Jackpot',
    des: '250000 CLV',
  },
  {
    image: JACKPOTIMG,
    title: 'Monthly Jackpot',
    des: '4999697 CLV',
  },
  {
    image: JACKPOTIMG,
    title: 'Yearly Jackpot',
    des: '300245785000 CLV',
  },
];

export const BETA_FEATURE = [
  {
    image: BETA1,
    title: 'Great Team',
    des: 'Top notch developers & designers.',
  },
  {
    image: BETA2,
    title: '24/7 Support',
    des: 'Always here for you.',
  },
  {
    image: BETA3,
    title: 'Design Focused Products',
    des: 'Best UI & UX experience.',
  },
];

export const menuWidget = [
  {
    id: 1,
    title: 'Company',
    menuItems: [
      {
        id: 4,
        url: '#portfolio_section',
        text: 'Work',
        offset: 100
      },
      {
        id: 4,
        url: '#services',
        text: 'Services',
        offset: 70
        
      },
      /*
      {
        id: 5,
        url: '#portfolio_section',
        text: 'Case Studies',
        offset: 40
      },
      */
      {
        id: 1,
        url: '#about-us',
        offset: 100,
        text: 'About Us',
      },
      {
        id: 2,
        url: 'https://studio.quaxo.tech/support',
        staticLink: true,
        text: 'Customer Support',
      }
    ],
  },
  {
    id: 2,
    title: 'Other',
    menuItems: [
      {
        id: 2,
        url: '#',
        text: 'Privacy Policy',
      },
      {
        id: 3,
        url: '#',
        text: 'Terms & Conditions'
      },
      {
        id: 4,
        url: '#process_section',
        text: 'Development Strategies',
        offset: 60
      },
      {
        id: 4,
        staticLink: true,
        url: 'mailto:hello@quaxo.tech',
        text: 'Contact Us',
      }
    ],
  },
  {
    id: 3,
    title: 'My Account',
    menuItems: [
      {
        id: 3,
        url: 'https://studio.quaxo.tech/profile',
        staticLink: true,
        text: 'Profile',
      },
      {
        id: 1,
        url: 'https://studio.quaxo.tech/projects',
        staticLink: true,
        text: 'Projects',
      },
      {
        id: 2,
        url: 'https://studio.quaxo.tech/add-project',
        staticLink: true,
        text: 'Send Project Request',
      }
    ],
  },
];
export const Language_NAMES = [
  {
    label: 'English',
    value: 'eng',
  },
  {
    label: 'Chinese',
    value: 'chinese',
  },
  {
    label: 'Indian',
    value: 'indian',
  },
];
