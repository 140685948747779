import React from 'react';
import PropTypes from 'prop-types';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Box from 'reusecore/src/elements/Box';
import { SocialIcon } from 'react-social-icons';
import Text from 'reusecore/src/elements/Text';
import Image from 'reusecore/src/elements/Image';
import Heading from 'reusecore/src/elements/Heading';
import Select from 'reusecore/src/elements/Select';
import Container from '../../../components/UI/Container';
import ContactSections from 'common/src/containers/Crypto/Contact';
import FooterWrapper, { List, ListItem, BgImageWrapper } from './footer.style';
import { menuWidget, Language_NAMES } from '../../../data/Crypto';
import AppImage from '../../../assets/image/ride/footerapp.svg';
import PlaystoreImage from '../../../assets/image/ride/footerplay.svg';
import FooterBG from '../../../assets/image/crypto/footer-bg.svg';
import EventEmitter from 'EventEmitter';

const Footer = ({ row, col, colOne, colTwo, titleStyle }) => {
  return (
    <FooterWrapper id="footerSection">
      <ContactSections />
      <BgImageWrapper>
        <Image src={FooterBG} alt="Footer background" />
      </BgImageWrapper>
      <Container noGutter mobileGutter width="1200px">
        <Box className="row mainRow" {...row}>
          <Box {...colOne}>
            <Heading
              content="Download Our App"
              {...titleStyle}
              className="appDownload"
            />
            <Box className="imageWrapper">
              <div href="#" onClick={()=>EventEmitter.emit('openModal', 'appWarningModal')} >
                <a>
                  <Image src={AppImage} alt="App Image" />
                </a>
              </div>
              <div href="#" onClick={()=>EventEmitter.emit('openModal', 'appWarningModal')} >
                <a>
                  <Image src={PlaystoreImage} alt="PlaystoreImage Image" />
                </a>
              </div>
            </Box>
          </Box>
          {/* End of footer logo column */}
          <Box {...colTwo}>
            {menuWidget.map(widget => (
              <Box className="col" {...col} key={widget.id}>
                <Heading content={widget.title} {...titleStyle} />
                <List>
                  {widget.menuItems.map(item => (
                    <ListItem key={`list__item-${item.id}`}>
                    {item.staticLink ? 
                   <a href={item.url} className="ListItem">{item.text}</a>
                    :
                    <AnchorLink
                      href={item.url}
                      offset={item.offset}
                    >
                    <a href={item.url} className="ListItem">{item.text}</a>
                    </AnchorLink>
                    }
                    </ListItem>
                  ))}
                </List>
              </Box>
            ))}
          </Box>
          {/* End of footer List column */}
        </Box>
        <Box className="row copyRight" {...row}>
          <div style={{display: 'flex', flexDirection: 'column'}} >
            <Text
              content="Copyright 2019 Quaxo Corporation."
              className="copyRightText"
            />
            <div className="social-icons" >
              <SocialIcon target="_blank" style={{height: 35, width: 35, marginRight: 15}} bgColor="white" url="https://facebook.com/quaxo.tech" />
              <SocialIcon target="_blank" style={{height: 35, width: 35, marginRight: 15}} bgColor="white" url="https://instagram.com/quaxo.tech" />
              <SocialIcon target="_blank" style={{height: 35, width: 35, marginRight: 15}} bgColor="white" url="https://linkedin.com/company/quaxo" />
              <SocialIcon target="_blank" style={{height: 35, width: 35, marginRight: 15}} bgColor="white" url="https://twitter.com/quaxo_tech" />
            </div>
          </div>
          <div className="companyContact" >
            <Text
              content="Sepapaja 6, Tallinn 15551, Estonia"
              className="copyRightText"
            />
            <Text
              content="info@quaxo.tech"
              className="copyRightText"
            />
          </div>
        </Box>
      </Container>
    </FooterWrapper>
  );
};

// Footer style props
Footer.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  colOne: PropTypes.object,
  colTwo: PropTypes.object,
  titleStyle: PropTypes.object,
  textStyle: PropTypes.object,
};

// Footer default style
Footer.defaultProps = {
  // Footer row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-4px',
    mr: '-4px',
  },
  // Footer col one style
  colOne: {
    width: ['100%', '30%', '33%', '33%'],
    mb: ['30px', 0],
    pl: ['0px', 0],
    pr: ['0px', '0px', 0],
  },
  // Footer col two style
  colTwo: {
    width: ['100%', '70%', '67%', '67%'],
    flexBox: true,
    flexWrap: 'wrap',
  },
  // Footer col default style
  col: {
    width: ['100%', 1 / 3, 1 / 3, 1 / 3],
    pl: [0, '15px'],
    pr: [0, '15px'],
    mb: ['30px', '30px'],
  },
  // widget title default style
  titleStyle: {
    color: '#FFFFFF',
    fontSize: ['15px', '16px', '16px', '18px', '18px'],
    fontWeight: '600',
    lineHeight: '1.34',
    mb: ['15px', '18px', '18px', '20px', '30px'],
    fontFamily: 'Poppins',
  },
  // Default logo size
  logoStyle: {
    width: '128px',
    mb: '15px',
  },
  // widget text default style
  textStyle: {
    color: '#FFFFFF',
    fontSize: '16px',
    mb: '12px',
    fontWeight: '600',
    fontFamily: 'Lato',
  },
};

export default Footer;
