import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import CustomImage from 'reusecore/src/elements/Image';
import Container from '../../../components/UI/Container';
import GlideCarousel from '../../../components/GlideCarousel';
import GlideSlide from '../../../components/GlideCarousel/glideSlide';
import {
  PortfolioShowcaseWrapper,
  PortfolioShowcaseItem,
  PortfolioLink,
  BuiltWith,
  PortfolioMeta,
  MetaItem,
} from './portfolioShowcase.style';
import {
  PrevButton,
  NextButton,
} from '../../../containers/Portfolio/portfolio.style';
import { PORTFOLIO_SHOWCASE } from '../../../data/Portfolio/data';

//Carousel Options
const carouselOptions = {
  type: 'carousel',
  perView: 1,
  gap: 0,
  animationDuration: 900,
};

class PortfolioShowcase extends Component {

  componentDidMount() {

      PORTFOLIO_SHOWCASE.forEach(tabItem => {

        tabItem.portfolioItem.forEach(portfolioItem => {

          const img = new Image();
          img.src = portfolioItem.image;

        });

      });
      
  }

  render() {

    const {
      sectionWrapper,
      secTitleWrapper,
      secTitle,
      secDescription,
      portfolioImage,
      portfolioDetails,
      titleStyle,
      detailsStyle,
    } = this.props;

    return (
      <Box {...sectionWrapper} as="section" id="portfolio_section">
        <Container noGutter mobileGutter width="1200px">
          <Box {...secTitleWrapper}>
            <Heading {...secTitle} content="Making Ideas Come to Life!" />
            <Text
              {...secDescription}
              content="Year after year, Quaxo Labs has been recognized as one of the top development & design firms in Tallin, Estonia. It’s nice to feel appreciated!"
            />
          </Box>

          <PortfolioShowcaseWrapper>
            <Tabs
              renderTabBar={() => <ScrollableInkTabBar />}
              renderTabContent={() => <TabContent animated={false} />}
            >
              {PORTFOLIO_SHOWCASE.map((tabItem, tabIndex) => {

                return (

                <TabPane
                  tab={
                    <Text
                      content={tabItem.title}
                      data-text={tabItem.title}
                      as="span"
                    />
                  }
                  key={tabIndex + 1}
                >
                  <GlideCarousel
                    carouselSelector={`portfolio-showcase-carousel-${tabIndex + 1}`}
                    options={carouselOptions}
                    tabIndex={tabIndex}
                    prevButton={
                      <PrevButton>
                        <span />
                      </PrevButton>
                    }
                    nextButton={
                      <NextButton>
                        <span />
                      </NextButton>
                    }
                  >
                    <>
                      {tabItem.portfolioItem.map((portfolioItem, index) => (
                        <GlideSlide key={`PortfolioShowcaseItem-${index}`}>
                          <PortfolioShowcaseItem>
                            <Box {...portfolioImage}>
                              <CustomImage
                                src={portfolioItem.image}
                                alt={`PortfolioImage-${index + 1}`}
                              />
                            </Box>
                            <Box {...portfolioDetails}>
                              <PortfolioLink>
                          
                              { tabIndex === 0 && <a href={portfolioItem.link} target="_blank" >VISIT LIVE SITE</a> }
                              { tabIndex === 1 && <a href={portfolioItem.link} target="_blank" >BROWSE APP</a> }
                            
                              </PortfolioLink>
                              <Heading
                                content={portfolioItem.title}
                                {...titleStyle}
                              />
                              <Text
                                content={portfolioItem.description}
                                {...detailsStyle}
                              />
                              {portfolioItem.buildWith ? (
                                <BuiltWith>
                                  {portfolioItem.buildWith.map((item, index) => (
                                    <span key={`buildWith-item-${index}`}>
                                      {item.content}
                                    </span>
                                  ))}
                                </BuiltWith>
                              ) : (
                                ''
                              )}
                            </Box>
                            { /*
                            {portfolioItem.featuredIn ||
                            portfolioItem.view ||
                            portfolioItem.love ||
                            portfolioItem.feedback ? (
                              <PortfolioMeta>
                                {portfolioItem.featuredIn ? (
                                  <MetaItem className="meta_featured">
                                    FEATURED IN
                              
                                      <a>{portfolioItem.featuredIn}</a>
                                
                                  </MetaItem>
                                ) : (
                                  ''
                                )}
                                {portfolioItem.view ? (
                                  <MetaItem>
                                    <b>{portfolioItem.view}</b> View
                                  </MetaItem>
                                ) : (
                                  ''
                                )}
                                {portfolioItem.love ? (
                                  <MetaItem>
                                    <b>{portfolioItem.love}</b> Love
                                  </MetaItem>
                                ) : (
                                  ''
                                )}
                                {portfolioItem.feedback ? (
                                  <MetaItem>
                                    <b>{portfolioItem.feedback}</b> Feedback
                                  </MetaItem>
                                ) : (
                                  ''
                                )}
                              </PortfolioMeta>
                            ) : (
                              ''
                            )}
                            */ }
                          </PortfolioShowcaseItem>
                        </GlideSlide>
                      ))}
                    </>
                  </GlideCarousel>
                </TabPane>

                )

                })}
            </Tabs>
          </PortfolioShowcaseWrapper>
        </Container>
      </Box>
    );

  }

};

PortfolioShowcase.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secTitle: PropTypes.object,
  secDescription: PropTypes.object,
  portfolioImage: PropTypes.object,
  portfolioDetails: PropTypes.object,
  titleStyle: PropTypes.object,
  detailsStyle: PropTypes.object,
};

PortfolioShowcase.defaultProps = {
  sectionWrapper: {
    pt: ['60px', '80px', '100px', '110px', '150px'],
    pb: ['60px', '80px', '100px', '110px', '150px'],
  },
  secTitleWrapper: {
    width: ['100%', '100%', '60%', '50%', '50%'],
    mb: ['50px', '65px'],
  },
  secTitle: {
    fontSize: ['22px', '26px', '26px', '30px', '30px'],
    fontWeight: '600',
    color: '#302b4e',
    lineHeight: '1.34',
    mb: ['15px', '18px', '18px', '20px', '20px'],
  },
  secDescription: {
    fontSize: ['15px', '16px'],
    fontWeight: '400',
    color: '#43414e',
    lineHeight: '1.5',
    mb: '0',
  },
  portfolioImage: {
    width: [1, 1, 1 / 2],
  },
  portfolioDetails: {
    width: [1, 1, 1 / 2],
    p: ['30px 0 0 0', '40px 0 0 0', '0 0 0 30px', '0 50px', '0 50px'],
  },
  titleStyle: {
    fontSize: ['22px', '22px', '26px', '40px', '40px'],
    fontWeight: '600',
    color: '#302b4e',
    mb: '17px',
  },
  detailsStyle: {
    fontSize: ['15px', '15px', '15px', '16px', '16px'],
    color: '#43414e',
    lineHeight: '1.5',
    mb: '0',
  },
};

export default PortfolioShowcase;
